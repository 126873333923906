import { reactive, toRefs, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useDebounce } from '@/hooks/core/useDebounce';
import API from '@/api/report/index';
import { useMessage } from '@/hooks/web/useMessage';
import dayjs from "dayjs";
import { Search } from "@element-plus/icons-vue";
import { formatDate } from '@/utils/formatTime';
import download from '@/utils/download';
export default {
  name: "CommandRecord",
  components: {
    // 自定义组件
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue')),
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue')),
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue'))
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      tableLoading: false,
      loading: false,
      total: 0,
      current: 1,
      pageSize: 100,
      value: 1,
      searchForm: {
        holdid: null,
        schoolId: null,
        objectname: '',
        mdtid: '',
        mdtid3: '',
        cmdtype: 0,
        sendresult: 0,
        alarmTime: [dayjs(new Date().getTime() - 60 * 60 * 24 * 3 * 1000).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      },
      tableData: [],
      tableColumns: [{
        title: '序号',
        align: 'center',
        key: 'index',
        width: 60,
        slots: {
          customRender: 'index'
        },
        visible: true
      }, {
        title: '设备名',
        align: 'center',
        key: 'objectname',
        visible: true
      }, {
        title: '所属单位',
        align: 'center',
        key: 'holdname',
        visible: true
      }, {
        title: '设备IMEI',
        align: 'center',
        key: 'mdtid',
        visible: true
      }, {
        title: '下发用户',
        align: 'center',
        key: 'userName',
        visible: true
      }, {
        title: '指令类型',
        align: 'center',
        key: 'cmdtype',
        slots: {
          customRender: 'cmdtype'
        },
        visible: true
      }, {
        title: '下发状态',
        align: 'center',
        key: 'sendresult',
        slots: {
          customRender: 'sendresult'
        },
        visible: true
      }, {
        title: '下发内容',
        align: 'center',
        key: 'cmddesc',
        slots: {
          customRender: 'cmddesc'
        },
        visible: true,
        width: 200
      }, {
        title: '应答内容',
        align: 'center',
        key: 'recvreply',
        slots: {
          customRender: 'recvreply'
        },
        visible: true,
        width: 200
      }, {
        title: '下发时间',
        align: 'center',
        key: 'createTime',
        width: 140,
        visible: true
      }]
    });
    const onSearch = async () => {
      refData.loading = true;
      if (refData.searchForm.alarmTime === null) {
        refData.searchForm.alarmTime = ['', ''];
      }
      const params = {
        holdid: refData.searchForm.holdid,
        pageNo: refData.current,
        pageSize: refData.pageSize,
        sendresult: !refData.searchForm.sendresult ? '' : refData.searchForm.sendresult,
        mdtid: refData.searchForm.mdtid,
        mdtid3: refData.searchForm.mdtid3,
        cmdtype: !refData.searchForm.cmdtype ? '' : refData.searchForm.cmdtype,
        startTime: refData.searchForm.alarmTime && refData.searchForm.alarmTime.length > 1 ? `${refData.searchForm.alarmTime[0]} 00:00:00` : '',
        endTime: refData.searchForm.alarmTime && refData.searchForm.alarmTime.length == 2 ? `${refData.searchForm.alarmTime[1]} 23:59:59` : ''
      };
      try {
        if (params.startTime == ' 00:00:00') throw {
          msg: "开始时间不能为空"
        };
        if (params.endTime == ' 23:59:59') throw {
          msg: "结束时间不能为空"
        };
        if (params.mdtid.length > 20) throw {
          msg: 'IMEI号错误，请输入正确IMEI号'
        };
        const {
          data,
          msg,
          code
        } = await API.getCmdist(params);
        refData.tableData = [];
        if (code > 0) {
          message.warning(msg);
        } else {
          refData.total = data.total;
          if (data.list) {
            refData.tableData = data.list.map(item => {
              item.createTime = formatDate(item.createTime);
              return item;
            });
          }
          refData.loading = false;
          if (data.total === 0) {
            return message.error('暂无数据');
          }
          message.success('数据已更新');
        }
      } catch (error) {
        refData.loading = false;
        message.warning(error.message);
      }
    };
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    //清空
    const handleEmpty = () => {
      refData.searchForm = {
        holdid: null,
        schoolId: null,
        objectname: '',
        mdtid: '',
        mdtid3: '',
        cmdtype: 0,
        sendresult: 0,
        alarmTime: ['', '']
      };
    };
    //导出
    const handleExport = async () => {
      refData.tableLoading = true;
      const params = {
        holdid: refData.searchForm.holdid || '',
        sendresult: !refData.searchForm.sendresult ? '' : refData.searchForm.sendresult,
        mdtid: refData.searchForm.mdtid,
        mdtid3: refData.searchForm.mdtid3,
        cmdtype: !refData.searchForm.cmdtype ? '' : refData.searchForm.cmdtype,
        startTime: refData.searchForm.alarmTime && refData.searchForm.alarmTime.length > 1 ? `${refData.searchForm.alarmTime[0]} 00:00:00` : '',
        endTime: refData.searchForm.alarmTime && refData.searchForm.alarmTime.length == 2 ? `${refData.searchForm.alarmTime[1]} 23:59:59` : ''
      };
      //await API.exportCmdist(params);
      const data = await API.exportCmdist(params);
      console.log(data);
      download.excel(data, '指令记录.xls');
      refData.tableLoading = false;
    };
    //组织数回调
    const handleClick = data => {
      if (data.type == 'org') {
        refData.searchForm.holdid = data.id;
        refData.searchForm.schoolId = null;
      } else {
        refData.searchForm.holdid = data.parentId;
        refData.searchForm.schoolId = data.id;
      }
      debounceOnSearch();
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleEmpty,
      handleExport,
      handleClick,
      Search
    };
  }
};